<template>
    <div>
        <!-- head title -->
        <div class="my-5">
          <span class="greytext--text font-21 d-block">{{
              $t("forms.welcome")
              }}</span>
            <span class="darkprimary--text font-33 font-700 d-block">{{ $t("forms.lastThing") }}</span>
        </div>
        <!-- Upload Signed Contract -->
        <v-form
                v-model="valid"
                ref="clinicFilesForm"
                @submit.prevent="submitHandler">
            <!-- Business Registration Documents -->
            <div class="mt-3">
              <span class="darktext--text font-18 font-600"
              >{{ $t("forms.businessRegistrationDocuments") }}
              </span>
                <div class="upload-file cursor-pointer">
                    <v-file-input
                            multiple
                            :placeholder="$t('forms.uploadFile')"
                            prepend-icon="require('@/assets/images/icons/plus-cover.svg')"
                            hide-details=""
                            v-model="documentations"
                            @change="fileHandler"
                            :rules="[]"
                    >

                    </v-file-input>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <!-- btn -->
                    <base-skip-btn
                            class="mt-4"
                            :title="$t('forms.skipAndUploadLater')"
                            @click="skipBtnHandler"
                    >
                    </base-skip-btn>
                </div>

                <div class="col-6">
                    <!-- btn -->
                    <base-register-btn
                            class="mt-4"
                            :title="$t('forms.next')"
                            type="submit"
                    >
                    </base-register-btn>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
export default {
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
    },
    data: () => ({
        obj: {},
        loading: false,
        contracts: [],
        documentations: [],
        valid: false,
    }),
    methods: {
        fileHandler(files) {
            this.documentations = [];
            let docs = [];
            files.forEach((file) => {
                if (file.size > 2049892) {
                    this.$store.dispatch("showSnack", {
                        text: this.$t("rules.fileSize"),
                        color: "error",
                    });
                } else {
                    docs.push(file);
                }
            });
            this.documentations = docs;
        },
        async submitHandler() {
            // Note the token is this.emailObj.token

            if (this.documentations.length === 0) {
                this.$store.dispatch("showSnack", {
                    text: this.$t('forms.skipUploadingFile'),
                    color: "success",
                });
                this.viewSignContract();
            } else {
                this.loading = true;
                let formData = new FormData();
                this.documentations.forEach((file) => {
                    formData.append(`documentations[]`, file);
                });
                formData.append('name', this.emailObj.name)
                formData.append('email', this.emailObj.email)
                formData.append('phone', this.emailObj.phone)
                formData.append('country_id', this.emailObj.country_id)
                formData.append('city_id', this.emailObj.city_id)
                let {data} = await this.$axios.post("profile/update", formData, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${this.emailObj.token}`,
                    },
                });
                if (data.success) {
                    this.$store.dispatch("showSnack", {
                        text: this.$t('forms.successUploadHospitalDoc'),
                        color: "success",
                    });

                    this.viewSignContract()
                } else {
                    this.$store.dispatch("showSnack", {
                        text:  this.$t('global.generalError'),
                        color: "error",
                    });
                }
            }
        },

        async skipBtnHandler() {
            this.viewSignContract();
        },

        viewSignContract() {
            this.$router.push(`/sign-contract-hospital?token=${this.emailObj.token}`);
        },
    },
};
</script>

<style lang="scss">
.download-btn {
  border: 1px solid $bordercolor;
  background: $lightgreen;
  border-radius: 10px;
  position: relative;

  &__link {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.upload-file {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: $lightgreen;
  padding: 0px !important;
  padding-left: 15px !important;
  padding-block: 10px;
  height: 45px;

  .v-input {
    padding-top: 0px;
    margin: 0px;

    &__icon--clear {
      right: 70px;
      position: relative;
      top: 8px;
    }

    .v-input__slot {
      &::after,
      &::before {
        display: none;
      }

     // cursor: pointer !important;
    }
  }

  .v-input,
  .v-input__control,
  .v-input__slot {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .icon {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;
  }
}
</style>
