<template>
    <div>
        <!-- head title -->
        <div
                class="d-inline-flex cursor-pointer align-center"
                @click="$emit('prevStep')"
        >
            <v-icon color="darkprimary">mdi-arrow-left</v-icon>
            <span class="darkprimary--text font-16 mx-2">{{ $t("dashboard.back") }}</span>
        </div>
        <div class="my-4">
      <span class="greytext--text font-21 d-block">{{
          $t("forms.welcomeDoctor")
          }}</span>
            <span class="darkprimary--text font-33 font-700 d-block mt-5"
            >{{ $t("forms.formAlso") }}
      </span>
        </div>

        <!-- phone number -->
        <v-form
                v-model="valid"
                ref="clinicGeneralForm"
                @submit.prevent="setHospitalInfo"
        >
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.phonenumber")
            }}</span>
                <v-row align="end">
                    <v-col cols="12" sm="6">
                        <base-auto-complete
                                :isPhone="true"
                                placeholder="Lebanon(+961)"
                                :items="filteredCountries"
                                itemValue="id"
                                itemText="name"
                                isCountrySelect
                                v-model="obj.country_code"
                        ></base-auto-complete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <base-input v-model="obj.phone" placeholder="9999999"></base-input>
                    </v-col>
                </v-row>
            </div>

            <v-row class="mt-4">
                <v-col cols="12" sm="6">
          <span class="darktext--text font-18 font-600">{{
              $t("forms.country")
              }}</span>
                    <base-auto-complete
                            noTitle
                            v-model="obj.country_id"
                            :placeholder="$t('forms.selectCoutry')"
                            :items="countries.countries"
                            @input="getCities"
                    ></base-auto-complete>
                </v-col>
                <v-col cols="12" sm="6">
          <span class="darktext--text font-18 font-600">{{
              $t("forms.city")
              }}</span>
                    <base-auto-complete
                            noTitle
                            v-model="obj.city_id"
                            :placeholder="$t('forms.selectCity')"
                            :items="cities"
                    ></base-auto-complete>
                </v-col>
            </v-row>

            <!-- btn -->
            <base-register-btn
                    class="mt-7"
                    :title="$t('forms.next')"
                    :loading="loading"
                    type="submit"
            >
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

import BaseAutoComplete from "../../../dashboard/components/BaseAutoComplete.vue";

export default {
    components: {BaseAutoComplete},
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
        savedObj: {
            type: Object,
            default: () => {
            },
        },
    },
    data: () => ({
        obj: {},
        loading: false,
        cities: [],
        valid: false,
    }),
    computed: {
        ...mapGetters(["countries"]),
        filteredCountries() {
            return this.countries.countries.map(item => {
                return {
                    ...item,
                    name: item.name + ' ' + ` (+${item.phone_code}) `
                }
            })
        }
    },
    methods: {
        handleNext() {
            let valid = this.$refs.clinicGeneralForm.validate();
            if (!valid) {
                return;
            }
            console.log('this.obk', this.obj)
            // this.$emit("click", this.obj);
        },

        async setHospitalInfo() {
            let valid = this.$refs.clinicGeneralForm.validate();
            if (!valid) {
                return;
            }
            this.loading = true;

            console.log(this.obj.country_id)
            let formData = new FormData();
            formData.append("email", this.emailObj.email);
            formData.append("city_id", this.obj.city_id);
            formData.append("country_id", this.obj.country_id);
            formData.append("phone", this.obj.phone);
            formData.append("representative_name", this.emailObj.representative_name);
            formData.append("name", this.emailObj.name);
            formData.append("institute_type", this.emailObj.institute_type);
            formData.append("country_code", this.obj.country_code);

            let res = await this.$axios.post("complete/registration", formData);

            this.loading = false;
            if (res.data) {
                this.emailObj.token = res.data.data.token
                this.$emit("click", this.obj);

            } else {
                this.$store.dispatch("showSnack", {
                    text: res.errors[Object.keys(res.errors)[0]][0],
                    color: "error",
                });
            }
        },
        async getCities(val) {
            let formData = new FormData();
            formData.append("country_id", val);
            let {data} = await this.$axios.post("cities/global", formData);
            if (data) {
                this.cities = data.data;
            }
        },
    },
    async created() {
        await this.getCities(this.savedObj.country_id);

        setTimeout(() => {
            this.obj = this.savedObj;
        }, 200);
    },
};
</script>

<style lang="scss"></style>
