<template>
    <div class="signup">
        <transition name="fade" mode="out-in">
            <!-- email step -->
            <email-step v-if="step == 1" @next="handleStepEmail"></email-step>

            <!-- verify step -->
            <verify-step
                    :key="step"
                    v-if="step == 2"
                    @next="handleStepVerify"
                    :emailObj="obj"
            ></verify-step>

            <!-- password step -->
            <password-step
                    :key="step"
                    v-if="step == 3"
                    @next="handleStepPassword"
                    :emailObj="obj"
            ></password-step>
            <!-- information step -->
            <info-step
                    v-if="step == 4"
                    @next="handleStepInfo"
                    :savedObj="savedObj"
            ></info-step>

            <!-- general step -->
            <general-step
                    :key="step"
                    v-if="step == 5"
                    @click="handleStepGeneral"
                    :emailObj="obj"
                    :savedObj="savedObj"
                    @prevStep="prevHandler(4)"
            ></general-step>
            <!-- last thing  step -->
            <last-thing-step
                    v-if="step == 6"
                    @click="step = 1"
                    :emailObj="obj"
                    @prevStep="prevHandler(5)"
            ></last-thing-step>
        </transition>

        <div class="text-center mt-10">
            <p class="greytext--text font-18">
                {{ $t("heroPage.alreadyAccount") }}
                <router-link to="/register/login"
                ><span class="font-18 darkprimary--text cursor-pointer font-700">{{
                    $t("heroPage.loginNow")
                    }}</span></router-link
                >
            </p>
        </div>
    </div>
</template>

<script>
import EmailStep from "./steps/Email";
import PasswordStep from "./steps/Password";
import VerifyStep from "./steps/Verify";
import InfoStep from "./steps/Info";
import GeneralStep from "./steps/General";
import LastThingStep from "./steps/LastThing";

export default {
    components: {
        EmailStep,
        PasswordStep,
        VerifyStep,
        InfoStep,
        GeneralStep,
        LastThingStep,
    },
    data: () => ({
        step: 1,
        obj: {},
        savedObj: {},
    }),
    methods: {
        handleStepEmail(obj) {
            this.obj = obj;
            this.step = 2;
        },
        handleStepVerify() {
            this.step = 3;
        },
        handleStepPassword() {
            this.step = 4;
        },
        handleStepInfo(val) {
            this.obj.institute_type = val.institute_type;
            this.obj.name = val.name;
            this.obj.representative_name = val.representative_name;
            this.step = 5;
            this.savedObj = {...this.obj};
        },
        handleStepGeneral(val) {
            this.obj.city_id = val.city_id;
            this.obj.country_id = val.country_id;
            this.obj.phone = val.phone;
            this.obj.country_code = val.country_code;
            this.step = 6;
            this.savedObj = {...this.savedObj, ...this.obj};
        },
        prevHandler(step) {
            this.step = step;
        },
    },
};
</script>

<style></style>
