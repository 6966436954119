<template>
    <div>
        <!-- head title -->
        <div class="my-10">
      <span class="darkprimary--text font-33 font-700">{{
          $t("forms.createPassword")
          }}</span>
        </div>

        <!-- Password -->
        <v-form
                v-model="valid"
                ref="clinicPasswordStep"
                @submit.prevent="handleNext"
        >
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.password")
            }}</span>
                <base-password
                        v-model="obj.password"
                        class="mt-1"
                        :placeholder="$t('forms.enterPassword')"
                        :hideDetails="false"
                ></base-password>
            </div>

            <!-- btn -->
            <base-register-btn
                    :title="$t('forms.create')"
                    :loading="loading"
                    type="submit"
            >
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
export default {
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
    },
    data: () => ({
        obj: {},
        loading: false,
        valid: false,
    }),
    methods: {
        async handleNext() {
            let valid = this.$refs.clinicPasswordStep.validate();
            if (!valid) {
                this.$store.dispatch("showSnack", {
                    text: this.$t("rules.passwordReuire"),
                    color: "error",
                });
                return;
            }
            this.loading = true;

            let formData = new FormData();
            formData.append("password", this.obj.password);
            formData.append("password_confirmation", this.obj.password);
            formData.append("email", this.emailObj.email);
            let res = await this.$axios.post("password/set", formData);

            this.loading = false;
            if (res.data) {
                this.$emit("next", res.data);
            } else {
                this.$store.dispatch("showSnack", {
                    text: res.errors.password[0],
                    color: "error",
                });
            }
        },
    },
};
</script>

<style></style>
