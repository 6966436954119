<template>
    <div>
        <!-- head title -->
        <div class="my-5">
            <span class="greytext--text font-21 d-block">{{ $t('forms.welcome') }}</span>
            <span class="darkprimary--text font-33 font-700 d-block"
            >{{ $t('forms.fillForm') }}</span
            >
        </div>

        <!-- Medical Institution Type * -->
        <v-form v-model="valid" ref="clinicInfoForm" @submit.prevent="handleNext">
            <div class="mt-3">
        <span class="darktext--text font-18 font-600"
        >{{ $t('forms.medicalInstitutionType') }} *</span
        >
                <base-auto-complete
                        :items="types"
                        v-model="obj.institute_type"
                        class="mt-1"
                        :placeholder="$t('forms.clinic')"
                ></base-auto-complete>
            </div>
            <!-- Clinic Name * -->
            <div class="mt-3">
                <span class="darktext--text font-18 font-600">{{ $t('forms.clinicName') }} *</span>
                <base-input
                        v-model="obj.name"
                        class="mt-1"
                        placeholder="Ex: Medical Clinic"
                ></base-input>
            </div>

            <!-- Representative name * -->
            <div class="mt-3">
        <span class="darktext--text font-18 font-600"
        >{{ $t('forms.representativeName') }} *</span
        >
                <base-input
                        v-model="obj.representative_name"
                        class="mt-1"
                        placeholder="Ex: John Doe"
                ></base-input>
            </div>

            <!-- btn -->
            <base-register-btn class="mt-4" :title="$t('forms.next')" type="submit">
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
import BaseAutoComplete from "../../../dashboard/components/BaseAutoComplete.vue";

export default {
    props: {
        savedObj: {
            type: Object,
            default: () => {
            }
        }
    },
    components: {
        BaseAutoComplete,
    },
    data: (vm) => ({
        obj: {},
        types: [
            {name: vm.$t("forms.clinic"), id: "clinic"},
            {name: vm.$t("forms.hospital"), id: "hospital"},
        ],
        valid: false,
    }),
    methods: {
        handleNext() {
            let valid = this.$refs.clinicInfoForm.validate();
            if (!valid) {
                return;
            }
            this.$emit("next", this.obj);
        },
    },
    created() {
        this.obj = this.savedObj
    }
};
</script>

<style></style>
