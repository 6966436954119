<template>
    <div>
        <!-- head title -->
        <div class="my-10">
      <span class="greytext--text font-21 d-block">{{
          $t("forms.welcome")
          }}</span>
            <span class="darkprimary--text font-33 font-700 d-block">{{
                $t("forms.createYourAccount")
                }}</span>
        </div>

        <!-- email -->
        <v-form v-model="valid" ref="clinicEmailStep" @submit.prevent="handleNext">
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.email")
            }}</span>
                <base-input
                        v-model="obj.email"
                        class="mt-1"
                        :type="$t('forms.email')"
                        placeholder="mail@info.com"
                        :rules="emailRules"
                        :hideDetails="false"
                ></base-input>
            </div>

            <!-- btn -->
            <base-register-btn
                    class="mt-2"
                    :title="$t('forms.next')"
                    :loading="loading"
                    type="submit"
            >
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
export default {
    data: (vm) => ({
        obj: {},
        loading: false,
        emailRules: [
            (v) => !!v || vm.$t("forms.emailRquired"),
            (v) => /.+@.+\..+/.test(v) || vm.$t("forms.emailValid"),
        ],
        valid: false,
    }),
    methods: {
        async handleNext() {
            let valid = this.$refs.clinicEmailStep.validate();
            if (!valid) {
                return;
            }
            this.loading = true;
            let formData = new FormData();
            formData.append("email", this.obj.email);
            let res = await this.$axios.post("institute/add", formData, {
                headers: {
                    Accept: "application/json",
                },
            });
            this.loading = false;
            if (res.success || res.status == 200) {
                this.$emit("next", res.data.data);
            } else {
                this.$store.dispatch("showSnack", {
                    text: res.message,
                    color: "error",
                });
            }
        },
    },
};
</script>

<style></style>
